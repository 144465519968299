// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mithelfen-js": () => import("./../src/pages/mithelfen.js" /* webpackChunkName: "component---src-pages-mithelfen-js" */),
  "component---src-pages-national-js": () => import("./../src/pages/national.js" /* webpackChunkName: "component---src-pages-national-js" */),
  "component---src-pages-regional-js": () => import("./../src/pages/regional.js" /* webpackChunkName: "component---src-pages-regional-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */)
}

